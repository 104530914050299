<template>
    <b-modal size="lg"
             centered
             hide-footer
             hide-header
             v-model="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="text-right">
            <button class="btn btn-outline-dark border-0"
                    v-on:click="hideModal">
                <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
        </div>

        <h1 class="d-none d-lg-block font-weight-bold text-center display-4 mb-3">
            {{ currentMonth }}月訂金折扣優惠
        </h1>
        <h1 class="d-lg-none font-weight-bold text-center mb-3">
            {{ currentMonth }}月訂金折扣優惠
        </h1>

        <p class="text-justify">
            <slot></slot>
        </p>

        <div class="text-center mb-3">
            <img class="payme-qrcode mb-3"
                 src="../../assets/marier_payme.jpg"
                 alt="marier PayMe">
            <h5 class="text-center mb-3">
                或按下連結
            </h5>
            <a class="btn btn-dark px-4"
               href="https://qr.payme.hsbc.com.hk/2/4M1woy1N5XDqn19XDEXd1M"
               rel="noreferrer nofollow noopener">
                前往PayMe
            </a>
        </div>

        <dl>
            <dt>
                條款及細則:
            </dt>
            <dd>
                <ol class="mb-0">
                    <li>
                        每位新登記客人只限享用優惠壹次及只限網上以PayMe繳付訂金專享。
                    </li>
                    <li>
                        每個療程之優惠上限不同：Ultra FEMME 360優惠上限為$500；EmSculpt極限增肌優惠上限為$100。
                    </li>
                    <li>
                        按金不設退款，如有任何爭議，marier Skincare曼肌兒保留最終決定權。
                    </li>
                </ol>
            </dd>
        </dl>

        <!-- <div class="px-3 px-lg-5 pb-4">
            <vue-slider adsorb
                        marks
                        v-bind:height="10"
                        v-bind:data="data"
                        v-model="amount">
                <template v-slot:label="{ active, value }">
                    <div class="vue-slider-mark-label"
                         v-bind:class="{ active }">
                        ${{ value }}
                    </div>
                </template>
            </vue-slider>
        </div> -->


    </b-modal>
</template>

<script>
    import {
        BModal,
    } from "bootstrap-vue";

    import VueSlider from "vue-slider-component";
    import "vue-slider-component/theme/default.css";

    const monthMap = [
        "一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二",
    ];

    export default {
        name: "ModalPayAsYouWish",
        components: {
            BModal,
            VueSlider,
        },
        props: {
            value: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                "data": [50, 100, 150, 200, 250],
                "amount": 150,
                "currentMonth": monthMap[new Date().getMonth()],
            };
        },
        computed: {

        },
        methods: {
            hideModal () {
                this.$emit("input", false);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .vue-slider-mark-label {
        font-size: 18px;

        &.active {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .payme-qrcode {
        width: 300px;
        height: auto;
    }
</style>
